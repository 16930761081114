<template>
  <div class="step-grid content-container-template">
    <div
      v-if="itemList.length"
      class="step-grid__grid"
    >
      <div
        v-for="(item, index) in itemList"
        :key="item.id"
        class="step-grid__item"
      >
        <span v-text="`${label[lang as keyof typeof label]} ${index + 1}`" />

        <T3HtmlParser
          v-if="item.name"
          :tag="item.text ? 'h3' : 'div'"
          :content="item.name"
          class="step-grid__item-title"
        />

        <T3HtmlParser
          v-if="item.text"
          :content="item.text"
          class="step-grid__item-text"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UiCeProductBoxesProps } from '~ui/types'

defineProps<UiCeProductBoxesProps>()

const label = {
  en: 'step',
  pl: 'krok',
  de: 'schritt'
}
</script>

<style lang="scss">
.step-grid {
  &.content-container-template {
    padding-inline: 0;
  }

  &__grid {
    display: grid;
    gap: rem(32px) rem(24px);
    grid-template-columns: repeat(auto-fit, minmax(rem(350px), 1fr));
    background-image: linear-gradient(
      to bottom,
      rgba(color(primary), 8%) 0%,
      rgba(color(primary), 0) 80%
    );
    padding: rem($container-padding);

    @include media-query(sm) {
      border-radius: rem(8px);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
    padding: rem(16px);
    background-color: color(dark-blue-1000);
    border: 1px solid color(dark-blue-900);
    border-radius: em(4px);

    box-shadow:
      0px 2px 5px 0px #0000000a,
      0px 9px 9px 0px #e0dede08,
      0px 20px 12px 0px #00000005,
      0px 36px 14px 0px #00000003;

    & > span {
      text-transform: uppercase;
      font-weight: 500;
      font-size: rem(16px);
      color: color(blue-1000);
    }

    &-title {
      font-weight: 600;
      font-size: rem(20px);
      color: color(white);
    }

    &-text {
      color: color(dark-blue-250);

      & > * {
        font-size: rem(16px);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
